import React, { FunctionComponent, HTMLAttributes } from "react";
import classnames from "classnames";
import classes from "./page-text.module.css";
import HtmlInfoText from "./html-info-text";

const PageText: FunctionComponent<{ text: string } & HTMLAttributes<HTMLDivElement>> = ({ text, className = "", ...props }) => (
    <div className={classnames(classes.container, className)} {...props}>
        <HtmlInfoText html={text} />
    </div>
);

export default PageText;
