import React, { FunctionComponent } from "react";
import Clock from "../images/clock.svg";

const SessionDuration: FunctionComponent<{ duration: string }> = ({ duration }) => (
    <p style={{
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Clock style={{ marginRight: '1rem' }} /> {duration} per sessie
    </p>
);

export default SessionDuration;