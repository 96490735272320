import React, { HTMLAttributes, FunctionComponent } from "react";
import classnames from "classnames";

import HtmlText from "./html-text";
import classes from "./html-info-text.module.css";

const HtmlInfoText: FunctionComponent<{ html: string } & HTMLAttributes<HTMLDivElement>> = ({ html, className = "", ...props }) => (
    <HtmlText html={html} className={classnames(classes.htmlInfoText, className)} {...props}></HtmlText>
);

export default HtmlInfoText;
