import React, { FunctionComponent } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import Hero from "../components/hero";
import { extractHtml, extractImage } from "../util";
import PageText from "../components/page-text";
import PageTitle from "../components/page-title";
import SessionDuration from "../components/session-duration";

const query = graphql`
    query {
        text: markdownRemark(fileAbsolutePath: {regex: "/bijles.md/"}) {
            html
        }
    }
`;

const Bijles: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { text } = extractHtml(data);

    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Bijles" description="De bijles van Praktijk de Leersprong is voor ieder kind. Hier leest u onze aanpak." />
            <Hero />

            <PageTitle>Bijles</PageTitle>
            <SessionDuration duration="45 minuten" />

            <Container>
                <PageText text={text} />
            </Container>
        </Layout>
    );
};

export default Bijles;