import React, { HTMLAttributes, FunctionComponent } from "react";

interface HtmlTextProps {
    html: string;
}

const HtmlText: FunctionComponent<HtmlTextProps & HTMLAttributes<HTMLDivElement>> = ({ html, ...props }) => (
    <div dangerouslySetInnerHTML={{ __html: html }} {...props}></div>
);

export default HtmlText;
